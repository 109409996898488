import React from "react";
import "../css/textPage.css"

import Layout from "../components/layout";
import SEO from "../components/seo"
import Slider from "../components/slidy"

const Kontakt = () => {
    const sliderImages = [
        {
            image: "IMG_8377.jpg",
            alt: "Haus"
        },
        {
            image: "IMG_2125.jpg",
            alt: "Verkaufsraum Außen"
        },
        {
            image: "IMG_8468.jpg",
            alt: "Verkaufsraum Schild"
        },
        {
            image: "IMG_2136.jpg",
            alt: "Labestation"
        },
        {
            image: "IMG_2205.jpg",
            alt: "Bienen"
        },
    ]

    return (
        <Layout>
            <SEO title="Kontakt"/>
            <div className="textPage">
                <h1>Besuchen Sie uns.</h1>
                <div className="textPageInfo">
                    <p>Wir sind für Sie täglich ab 14 Uhr da. Schauen Sie vorbei und entdecken Sie die Vielzahl an
                        unseren Produkten.</p>
                    <a className="link"
                       href="https://www.google.com/maps/place/Halbenrain+188,+8492+Halbenrain/@46.7338208,15.9466393,17z/data=!3m1!4b1!4m13!1m7!3m6!1s0x476f11922e39faa1:0x601f59ee709dbabd!2sHalbenrain+188,+8492+Halbenrain!3b1!8m2!3d46.7338208!4d15.948828!3m4!1s0x476f11922e39faa1:0x601f59ee709dbabd!8m2!3d46.7338208!4d15.948828">8492
                        Halbenrain 188</a> <br/> <br/>
                    <p>oder melden Sie sich:</p>
                    <a className="link" href="tel:+436645726615">+43(0)664 5726615</a> <br/> <br/>
                </div>
            </div>
            <Slider sliderImages={sliderImages} />
        </Layout>
    )
}

export default Kontakt